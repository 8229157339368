import * as React from "react"
import { Helmet } from "react-helmet"

const TVRPrivacyPolicy = () => {
  return (
    <main>
      <Helmet>
        <title>TV Romania X: Privacy Policy</title>
      </Helmet>

      <div class="p-8 lg:p-16">
        <div class="text-lg">
          <h1>
            <span class="block text-base text-neutral-600 font-semibold tracking-wide uppercase">TV Romania X</span>
            <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-neutral-900 sm:text-4xl">Privacy Policy</span>
          </h1>
        </div>
        <div class="mt-8 prose prose-lg text-neutral-500">
          <p>
            We DO NOT collect any data from this app.
          </p>
        </div>
      </div>
    </main>
  )
}

export default TVRPrivacyPolicy